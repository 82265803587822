import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { RegisterForm } from "./RegisterForm";
import getCSRFToken from "../../stores/CSRFStore";
import Background from "../../components/generic/Background";
import "./index.scss";
import Button from "../../components/generic/Button";
import Cookies from "js-cookie";
import { Colors } from "../../components/generic/Colors";
import TextStrings from "../login/Strings";
import { Link } from "react-router-dom";

function RegisterView() {
  getCSRFToken();
  const searchParams = new URLSearchParams(window.location.search);
  const via = searchParams.get("via");

  const [referral, setReferral] = React.useState(via);

  return (
    <Background>
      <Helmet>
        <title>Rejestracja | Gaius Lex</title>
        <meta
          name="description"
          content="Rejestracja do Gaius Lex - Twojego pomocnika w świecie prawa"
        />
        <link
          rel="canonical"
          href={process.env.BACKEND_ENDPOINT + "/pl/register"}
        />
      </Helmet>
      <MDBRow className="row flex-grow-1 w-100 m-0">
        <MDBCol className="col d-flex flex-column col-sm-12 col-s-12 col-12 px-0">
          <MDBCard id="register-form-card" className="my-auto mx-auto" style={{
            maxWidth: "600px",
            width: "100%",
          }}>
              <MDBCardBody>
                {referral ? <RegisterForm /> : <RegisterQuote/>}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

    </Background>
  );
}

const RegisterQuote = () => {
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    specialisation: "Prawo cywilne",
    specialistaionAnother: "",
    disabled: false,
    send: false
  })

  return (
    <div>
      <div id="register-header">
        <h2 className="h2-header">Witaj w Gaius Lex</h2>
        <p className="payments__header-p">Skontaktuj się z nami, a przygotujemy ofertę specjalnie dla Ciebie!</p>
      </div>

      <div className="payments__quote" style={{ maxWidth: "100%", marginTop: 20 }}>
        {formData.send ?
          <>
            <h2 className="payments__header-h2">Twoje zapytanie zostało przesłane</h2>
            <p className="payments__header-p">Dziękujemy za kontakt, nasza obsługa klienta skontaktuje się z Tobą telefonicznie.</p>
          </>
          :
          <>

            <input placeholder={"Imię"} autoComplete="tel" className="gaius-input" value={formData.name} onChange={(e) => { setformData({ ...formData, name: e.target.value }) }} />
            <input placeholder={"jan@example.com"} autoComplete="email" className="gaius-input" value={formData.email} onChange={(e) => { setformData({ ...formData, email: e.target.value }) }} />
            <input placeholder={"Numer telefonu"} autoComplete="tel" className="gaius-input" value={formData.phone} onChange={(e) => { setformData({ ...formData, phone: e.target.value, disabled: false }) }} />

            <label htmlFor="specjalizacja">Specjalizacja</label>
            <select id="specjalizacja" onChange={(e) => { setformData({ ...formData, specialisation: e.target.value }) }} className="gaius-input">
              <option value="Prawo cywilne" selected>Prawo cywilne</option>
              <option value="Prawo karne">Prawo karne</option>
              <option value="Prawo korporacyjne/biznesowe">Prawo korporacyjne/biznesowe</option>
              <option value="Prawo pracy">Prawo pracy</option>
              <option value="Prawo rodzinne">Prawo rodzinne</option>
              <option value="Prawo nieruchomości">Prawo nieruchomości</option>
              <option value="Inna">Inna</option>
            </select>

            {formData.specialisation == "Inna" && <input placeholder={"Specjalizacja"} type="text" className="gaius-input" value={formData.specialistaionAnother} onChange={(e) => { setformData({ ...formData, specialistaionAnother: e.target.value }) }} />}

          </>
        }

        <Button
          variant="contained-dark"
          type="button"
          onClick={() => {
            fetch("https://hook.eu2.make.com/iefu88llk8yrw69flrx5vvw4dxca3icy", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(formData)
            }).then(() => {
              Cookies.set("quote", true, { expires: 3 }); // 3 days
              setformData({ ...formData, send: true })
            });
          }}
          disabled={!(formData.name && formData.email && formData.phone) || formData.send}>

          {formData.send ? "Wysłano zapytanie" : "Wyślij zapytanie"}
        </Button>


      </div>
      <Link
        to={`/login`}
        className="text-center"
        style={{ marginTop: 15, color: Colors.DarkGrey, textDecoration: "none", textAlign: "left" }}
      >
        Masz już konto? Zaloguj się
      </Link>
    </div>
  );
}

export default RegisterView;
