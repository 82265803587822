import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MusicNote from "@mui/icons-material/MusicNote";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LockIcon from "@mui/icons-material/Lock";
import {
  Card,
  Box,
  Stack,
  Grid,
  Typography,
  LinearProgress,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Chip,
  Tooltip,
} from "@mui/material";
import Button from "../../components/generic/Button";
import * as React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDropzone } from 'react-dropzone';
import DataUsageNoticeModal from "../../components/dataUsageNoticeModal";
import useUserData from "../../hooks/useUserData";
import TextStrings from "./Strings";

const MAX_DOCUMENTS_AMOUNT = 10;

interface DocumentProps {
  type: string;
  name: string;
  fileDate: string;
  fullPath: string;
  filePrivate: boolean;
  fileEncrypted: boolean;
  refresh: Function;
  status: string;
  finished: boolean;
  length: number;
  callback?: Function;
}

interface IconProps {
  [key: string]: JSX.Element | (string | JSX.Element)[] | undefined;
}

const ICONS: IconProps = {
  pdf: <PictureAsPdfIcon />,
  docx: <ArticleIcon />,
  txt: <ArticleIcon />,
  mp3: <MusicNote />,
  wav: <MusicNote />,
  more: <MoreHorizIcon />,
  delete: <DeleteOutlineIcon fontSize="small" />,
  lock: <LockIcon fontSize="small" />,
  unlock: <LockOpenIcon fontSize="small" />,
};

const statusMapping = (status: string) => {
  // x_y_0 -> x_y
  const percentage = status.split("_").pop();
  status = status.replace(/_\d+$/, "");

  switch (status) {
    case "queue":
      return "W kolejce";
    case "reading_file":
      return "Czytanie pliku";
    case "parsing":
      return `Czytanie pliku: ${percentage}%`;
    case "vectorizing":
      return `Przetwarzanie: ${percentage}%`;
    default:
      return "Nieznany";
  }
};

const Document = ({
  type,
  name,
  fileDate,
  fullPath,
  filePrivate,
  fileEncrypted,
  refresh,
  status,
  finished,
  length,
  callback,
}: DocumentProps) => {
  const snackbar = React.useContext(SnackbarContext);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [isPrivate, setIsPrivate] = React.useState<boolean>(filePrivate);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function deleteDocument() {
    getCSRFToken();
    axios
      .delete(`api/v1/delete-document?path=${fullPath}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data);
        refresh();
        snackbar.setMessage("Dokument został usunięty");
        snackbar.setSeverity("success");
        snackbar.setOpen(true);
      })
      .catch((error) => {
        console.log(error);
        snackbar.setMessage("Wystąpił błąd podczas usuwania dokumentu");
        snackbar.setSeverity("error");
        snackbar.setOpen(true);
      });
  }

  function updatePrivate() {
    getCSRFToken();

    const formData = new FormData();
    formData.append("path", fullPath);
    formData.append("private", !isPrivate ? "True" : "False");
    axios
      .post(`api/v1/update-document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        snackbar.setMessage(
          `Plik oznaczony jako ${!isPrivate ? "prywatny" : "publiczny"}`
        );
        setIsPrivate(!isPrivate);
        handleMenuClose();
        snackbar.setSeverity("success");
        snackbar.setOpen(true);
      })
      .catch((error) => {
        console.log(error);
        snackbar.setMessage(error.message);
        snackbar.setSeverity("error");
        snackbar.setOpen(true);
      });
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 250,
        float: "left",
        margin: 0.5,
      }}
    >
      <Card className="p-3 my-1 shadow-0 border">
        <Box sx={{ display: "flex" }} height={150}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Stack
              direction="column"
              justifyContent={"space-between"}
              className="h-100"
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    marginRight: "16px",
                    color: "#FA6E68",
                  }}
                >
                  {ICONS[type]}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "bold",
                      wordBreak: "break-all",
                      width: 150,
                      maxHeight: 80,
                      textWrap: "wrap",
                      overflow: "auto",
                    }}
                  >
                    {finished ? (
                      <Link
                        to={`/show-document?path=${fullPath}`}
                        target="_blank"
                      >
                        {name}
                      </Link>
                    ) : (
                      name
                    )}
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  display: "block",
                  width: "100%",
                  flexDirection: "row",
                  color: "text.secondary",
                  margin: "10px 0",
                }}
              >
                {isPrivate && (
                  <Chip
                    label={"PRYWATNY"}
                    size="small"
                    sx={{
                      width: "fit-content",
                      backgroundColor: "#FA6E68",
                      color: "white",
                      marginRight: 5,
                    }}
                  />
                )}
                {fileEncrypted && (
                  <Tooltip
                    title={TextStrings.document.encrypted_tooltip}
                  >
                    <Chip
                      label={"ZASZYFROWANY"}
                      size="small"
                      sx={{
                        width: "fit-content",
                        backgroundColor: "#0e2451",
                        color: "white",
                      }}
                    />
                  </Tooltip>
                )}
                <div style={{ float: "right" }}>{length} słów</div>
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Divider sx={{ borderBottom: "1px solid black" }} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box style={{ padding: 0, margin: 0, color: "text.secondary" }}>
            {new Date(fileDate).toLocaleDateString("pl-PL")} r.
          </Box>
          <Box style={{ padding: 0, margin: 0, color: "text.secondary" }}>
            {new Date(fileDate).toLocaleTimeString("pl-PL")}
          </Box>
        </Stack>
      </Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "16px",
          width: "100%",
        }}
      >
        {finished ? (
          <Button
            sx={{ marginLeft: "8px", marginRight: "10px" }}
            variant="contained-dark"
            width={"100%"}
            onClick={() => {
              if (callback) {
                callback(fullPath);
              }
              else {
                navigate(`/contract-analysis?filePath=${fullPath}`);
              }
            }}
          >
            {callback && "Wybierz" || "Przeanalizuj"}
          </Button>
        ) : (
          <Button
            id={"status-button"}
            sx={{ marginLeft: "8px", marginRight: "10px" }}
            variant="contained-dark"
            width={"100%"}
            disabled
          >
            {statusMapping(status)}
          </Button>
        )}
        <Button
          id={"more-button"}
          variant="outlined-light"
          onClick={handleMenuClick}
          color="white"
          sx={{
            color: "#99B2C6",
            borderColor: "#99B2C6",
            minWidth: "unset",
            display: "flex",
            float: "right",
            width: 50,
          }}
        >
          {ICONS["more"]}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              deleteDocument();
              handleMenuClose();
            }}
          >
            <ListItemIcon>{ICONS["delete"]}</ListItemIcon>
            Usuń dokument
          </MenuItem>
          <MenuItem
            onClick={() => {
              updatePrivate();
            }}
          >
            <ListItemIcon>
              {isPrivate ? ICONS["unlock"] : ICONS["lock"]}
            </ListItemIcon>
            {isPrivate ? "Daj dostęp Gaiusowi" : "Oznacz jako prywatny"}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export const UploadDocument = ({
  collection,
  refresh,
  filesAmount,
}: {
  collection: string;
  refresh: Function;
  filesAmount: number;
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: files => setFile(files.length ? files[0] || null : null),
        accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".bmp"],
      "application/*": [".pdf"],
      // This is required to show *.pdf files in some chrome versions
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "audio/*": [".mp3", ".wav", ".m4a", ".flac", ".ac3"],
      "application/msword": [".docx"],
    },
  });
  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();
  const [showDataUsageNotice, setShowDataUsageNotice] = React.useState<boolean>(false);
  const { userData } = useUserData();
  getCSRFToken();

  function uploadDocument() {
    if (!!!userData.dataUsageNotice) {
      setShowDataUsageNotice(true);
      return;
    }

    if (filesAmount >= MAX_DOCUMENTS_AMOUNT) {
      snackbar.setMessage(
        "Możliwość wysłania maksymalnie 10 dokumentów. Usuń któryś z dokumentów jeśli chcesz wysłać kolejny"
      );
      snackbar.setDuration(10000);
      snackbar.setSeverity("error");
      snackbar.setOpen(true);
      return;
    }

    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", collection);
      axios
        .post(`api/v1/vectorize`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          refresh();
          setLoading(false);
          setFile(null);
          snackbar.setMessage("Plik został wysłany");
          snackbar.setSeverity("success");
          snackbar.setOpen(true);
        })
        .catch((error) => {
          console.log(error);
          snackbar.setMessage(error.message);
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
          setLoading(false);
        });
    }
  }

  return (
    <div id={"upload-document"}>
      <LoadingOverlay
        active={loading}
        spinner={<LinearProgress />}
        text={<div style={{ fontSize: "16px" }}>Wysyłanie pliku...</div>}
        className="loading-overlay"
      >
        <Box
          id={"file-upload-box"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: "16px 0",
            border: "1px dashed gray",
          }}
        >
          {!loading && (
            <div style={{ width: "100%" }}>
              <Box
                className="w-100 h-100"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  id={"file-upload-stack"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={{
                    sx: "column",
                    md: "row",
                  }}
                >
                  <section className="drag-n-drop-container">
                    <div {...getRootProps({ className: "drag-n-drop-dropzone" })}>
                      <input {...getInputProps()} />
                      <div id={"file-upload-label"}>
                        <UploadFileIcon />
                        Wybierz plik
                      </div>
                    </div>
                  </section>
                </Stack>
              </Box>
            </div>
          )}
        </Box>
        <Box sx={{ margin: "16px", wordBreak: "break-all" }}>{file?.name}</Box>
        <Button
          variant="contained-dark"
          onClick={uploadDocument}
          width={"100%"}
        >
          Wyślij
        </Button>
      </LoadingOverlay>
      {showDataUsageNotice && <DataUsageNoticeModal />}
    </div>
  );
};

const DocumentBrowser = ({ collection, minimal, callback }: { collection: string, minimal?: boolean, callback?: Function }) => {
  const [files, setFiles] = React.useState([]);
  const axios = useAxiosPrivate();

  function refresh() {
    // getCSRFToken();
    axios
      .get(`api/v1/list-documents?path=${collection}`)
      .then((response) => {
        console.log(response.data);
        setFiles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  React.useEffect(() => {
    refresh();

    const interval = setInterval(() => {
      refresh();
    }, 5000);

    return () => clearInterval(interval);
  }, [collection]);

  return (
    <>
      {!minimal &&
        <UploadDocument
          collection={collection}
          refresh={refresh}
          filesAmount={files.length}
        />
      }
      <Grid xs={12} sm={12} md={12} mt={6} mb={2}>
        <Grid item xs={8} width={"fit-content"} style={{ float: "left" }}>
          <h1 className="mb-3">Twoja baza dokumentów</h1>
          <Typography variant="h3" sx={{ fontSize: "0.9rem" }}>
            Wysłane pliki pojawią się poniżej
          </Typography>
        </Grid>
        <Tooltip
          title={`Możliwość wysłania maksymalnie ${MAX_DOCUMENTS_AMOUNT} dokumentów`}
        >
          <Grid
            item
            xs={4}
            width={"fit-content"}
            style={{
              float: "right",
              backgroundColor:
                files.length >= MAX_DOCUMENTS_AMOUNT ? "#FA6E68" : "#0e2451",
              color: "white",
              padding: 10,
              borderRadius: "0.75rem",
            }}
          >
            Liczba dokumentów:{" "}
            <strong>
              {files.length} / {MAX_DOCUMENTS_AMOUNT}
            </strong>
          </Grid>
        </Tooltip>
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
          border: "1px solid #f2f2f2",
          borderRadius: "8px",
          padding: "32px",
        }}
      >
        {files.length > 0 &&
          files.map((file: any, index: any) => {
            const fileType: string = file.path.split(".").pop() || "directory";
            const fileName: string = file.path.split("/").pop();
            const status = file.status;
            const finished = file.finished;
            // round length to hundreds/tens
            const length =
              file.length > 100
                ? Math.round(file.length / 100) * 100
                : Math.round(file.length / 10) * 10;

            return (
              <Document
                key={index}
                type={fileType}
                name={fileName}
                fullPath={file.path}
                fileDate={file.added}
                filePrivate={file.private}
                fileEncrypted={file.encrypted}
                refresh={refresh}
                status={status}
                finished={finished}
                length={length}
                callback={callback}
              />
            );
          })}
      </Box>
    </>
  );
};

export default DocumentBrowser;
