// @ts-ignore
import Cookies from 'js-cookie';
import React, { useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from "../../components/generic/Button";
import PaymentStore from "../../stores/PaymentStore";
import useUserData from "../../hooks/useUserData";
import "./Payments.scss";
import PaymentsHistory from "./PaymentsHistory";

interface PaymentBox2Props {
  type: string; //TODO
  oldPrice: string;
  price: string;
  period: string;
  comment: string;
  lowest: string;
  periodCode: string;
  link: string;
};

const PaymentBox2: React.FC<PaymentBox2Props> = ({ type, price, period, comment, lowest, periodCode, link }) => {
  const paymentStore = React.useState(PaymentStore())[0];

  return (
    <>
      <div className="payments__version">

        <div className="payments__version">
          <div className="payments__version__header">
            <div className="payments__version__type">{type}</div>
            <div className="payments__version__price">{price} <span>zł</span></div>
            <div className="payments__version__period">{period}</div>
            <div className="payments__version__comment">{lowest ? "najniższa cena w ciągiu 30 dni: " + lowest : "\u00A0"}</div>
            <div className="payments__version__comment">{comment ? comment : "\u00A0"}</div>
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dostęp do modułu orzeczeń</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dostęp do modułu kodeksów</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dostęp do modułu interpretacji podatkowych</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dostęp do modułu „Porozmawiaj z gaiusem”</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dostęp do modułu „Analizuj moje dokumenty”</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros" style={{ minHeight: 82 }}>
            <p className="payments__version__pros__text">Dostęp do zamkniętego newslettera prawnego informującego o najważniejszych nowinach z prawa co piątek 15:00</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros" style={{ minHeight: 64 }}>
            <p className="payments__version__pros__text">Newsletter o nowych aktualizacjach/danych i poczynań Gaius-Lexa na międzynarodowym rynku</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Wsparcie techniczne 9:00 – 21:00</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
          <div className="payments__version__pros">
            <p className="payments__version__pros__text">Dedykowany opiekun klienta</p>
            <CheckCircleOutlineIcon className="pbib-tick" />
          </div>
        </div>
        <a className="payments__button" onClick={() => {
          paymentStore.buySubscription("/", link).then((res: any) => {
            document.location.href = res.href;
          })
        }}>
          <Button
            variant="contained-dark"
            type="submit">
            Wybierz
          </Button>
        </a>
      </div>

    </>);
}

const PaymentsOneOption: React.FC<PaymentBox2Props> = ({ type, oldPrice, price, period, comment, lowest, periodCode, link }) => {
  const paymentStore = React.useState(PaymentStore())[0];

  return (
    <div className="paymentsOneOption__container">
      <div className="paymentsOneOption">
        <div className="payments__version__header paymentsOneOption__header">
          <div className="payments__version__type">{type}</div>
          <div className="payments__version__price paymentsOneOption__price"><span className="paymentsOneOption__old-price">{oldPrice}zł</span>{price} <span>zł*</span></div>
          <div className="payments__version__period">{period}</div>
          <div className="payments__version__comment">{comment ? comment : "\u00A0"}</div>
          <div className="payments__version__comment">{lowest ? "najniższa cena w ciągiu 30 dni: " + lowest : "\u00A0"}</div>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Dostęp do modułu orzeczeń</p>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Dostęp do modułu kodeksów</p>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Dostęp do modułu interpretacji podatkowych</p>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Dostęp do modułu „Porozmawiaj z gaiusem”</p>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Dostęp do modułu „Analizuj moje dokumenty”</p>
        </div>
        <div className="payments__version__pros" style={{ minHeight: 82 }}>
          <p className="payments__version__pros__text">Dostęp do zamkniętego newslettera prawnego informującego o najważniejszych nowinach z prawa co piątek 15:00</p>
        </div>
        <div className="payments__version__pros" style={{ minHeight: 64 }}>
          <p className="payments__version__pros__text">Newsletter o nowych aktualizacjach/danych i poczynań Gaius-Lexa na międzynarodowym rynku</p>
        </div>
        <div className="payments__version__pros">
          <p className="payments__version__pros__text">Wsparcie techniczne 9:00 – 21:00</p>
        </div>
        <div className="payments__version__pros" style={{ borderRadius: "0 0 16px 16px" }}>
          <p className="payments__version__pros__text">Dedykowany opiekun klienta</p>
        </div>
        <a className="payments__button" onClick={() => {
          paymentStore.buySubscription("/", link).then((res: any) => {
            document.location.href = res.href;
          })
        }}>
          <Button
            variant="contained-dark"
            type="submit">
            Wybierz
          </Button>
        </a>
      </div>

    </div>
  );
}

const SubscriptionStatus = ({ isSubscribed, tillTrialEnds, isTrial, isCanceled, endDate }: { isSubscribed: boolean, tillTrialEnds: number, isTrial: boolean, isCanceled: boolean, endDate: string }) => {
  const [modalData, setmodalData] = useState({ step: 1, open: false, disabled: false });
  const paymentStore = React.useState(PaymentStore())[0];

  return (
    <>
      {
        tillTrialEnds != undefined && tillTrialEnds > 0 &&
        <div className="payments__alert payments__box">
          <p className="payments__alert-text">
            Twój dostęp testowy wygaśnie automatycznie za <span>{tillTrialEnds} dni.</span> Jeżeli nie dokonasz zakupu, Twoje konto nie zostanie obciążone.
          </p>
        </div>
      }

      {
        tillTrialEnds != undefined && tillTrialEnds == 0 &&
        <div className="payments__alert payments__box">
          <p className="payments__alert-text">
            Twój dostęp testowy wygaśnie <span>dzisiaj</span>. Jeżeli nie dokonasz zakupu, Twoje konto nie zostanie obciążone.
          </p>
        </div>
      }


      {
        isSubscribed && isTrial === false &&
        <div>
          <div className="payments__box">
            <h2 className="payments__header-h2">Posiadasz już aktywną subskrypcję Gaius Lex</h2>
            <p className="payments__header-p">{endDate && (isCanceled || modalData.step == 2 ? "Subskrypcja anulowana. Aktualny abonament ważny do " + endDate : "Subskrypcja ważna do " + endDate + ".")}</p>
            <div className="payments__subscription-controls">
              <Button
                variant="contained-dark"
                type="submit"
                onClick={() => {
                  window.open("https://billing.stripe.com/p/login/00g15ldjy4jKceY4gg", "_blank");
                }}>
                Przejdź do portalu płatności
              </Button>
              {isCanceled || modalData.step == 2 ? "Subskrypcja anulowana" :
                <button
                  className="payments__cancel-btn"
                  type="button"
                  onClick={() => {
                    setmodalData({ ...modalData, open: true, step: 1 })
                  }}>
                  Anuluj subskrypcje
                </button>}
            </div>
          </div>
          {modalData.open &&
            <div className="payments__modal-container">
              <div className="payments__modal">
                <main className="payments__modal__container">
                  <h2 className="payments__header-h2">{modalData.step == 1 ? "Czy na pewno chcesz anulować subskrypce?" : "Abonament anulowany poprawnie"}</h2>
                  <p className="payments__header-p">{modalData.step == 1 ? "Aktualna subskrypcja zakończy się wraz z okresem rozliczeniowym i nie zostanie już przedłużona. Powrót do aktualnej ceny może już nie być możliwy." : "Przykro nam, że rezygnujesz z Gaius Lex."}</p>
                </main>
                <footer className="payments__modal__footer">
                  {modalData.step == 1 &&
                    <div className="payments__modal__footer__btn-container">
                      <button
                        className="payments__cancel-btn"
                        type="button"
                        disabled={modalData.disabled}
                        onClick={(e) => {
                          setmodalData({ ...modalData, disabled: true })
                          paymentStore.cancelSubscription().then((res: any) => {
                            setmodalData({ ...modalData, step: 2 })
                          }).catch((err) => {
                            setmodalData({ ...modalData, disabled: false })
                          });
                        }}>
                        {modalData.disabled ? "Anulowanie.." : "Tak, anuluj"}
                      </button>
                      <Button
                        variant="contained-dark"
                        type="submit"
                        onClick={() => {
                          setmodalData({ ...modalData, open: false });
                        }}>
                        Nie
                      </Button>
                    </div>}
                  {modalData.step == 2 &&
                    <div className="payments__modal__footer__btn-container">
                      <button
                        className="payments__cancel-btn"
                        type="button"
                        onClick={() => {
                          setmodalData({ ...modalData, open: false });
                        }}>
                        Zamknij
                      </button>
                      {/*<Button*/}
                      {/*    variant="contained-dark"*/}
                      {/*    type="submit"*/}
                      {/*    onClick={() => {*/}
                      {/*      setmodalData({...modalData, open: false, step: 1});*/}
                      {/*    }}>*/}
                      {/*  Zostaw opinie*/}
                      {/*</Button>*/}
                    </div>}

                </footer>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

const QuoteForm = ({ isSubscribed, isTrial, name, email, phone }: { isSubscribed: boolean, isTrial: boolean, name: string, email: string, phone: string }) => {
  const quote = Cookies.get("quote");

  const [formData, setformData] = useState({
    name: name,
    email: email,
    phone: phone,
    specialisation: "Prawo cywilne",
    specialistaionAnother: "",
    disabled: quote || !phone ? true : false,
    send: false
  })

  return (
    <div className="payments__quote">
      {quote || formData.send ?
        <>
          <h2 className="payments__header-h2">Twoje zapytanie zostało przesłane</h2>
          <p className="payments__header-p">Dziękujemy za kontakt, nasza obsługa klienta skontaktuje się z Tobą telefonicznie.</p>
        </>
        :
        <>
          <h2 className="payments__header-h2">Chcesz {(isSubscribed && !isTrial) ? "zmienić" : "aktywować"} subskrypcję Gaius Lex?</h2>
          <p className="payments__header-p">Skontaktuj się z nami, a przygotujemy ofertę specjalnie dla Ciebie!</p>

          {!phone && <input placeholder={"Numer telefonu"} autoComplete="tel" className="gaius-input" value={formData.phone} onChange={(e) => { setformData({ ...formData, phone: e.target.value, disabled: false }) }} />}

          <label htmlFor="specjalizacja">Specjalizacja</label>
          <select id="specjalizacja" onChange={(e) => { setformData({ ...formData, specialisation: e.target.value }) }} className="gaius-input">
            <option value="Prawo cywilne" selected>Prawo cywilne</option>
            <option value="Prawo karne">Prawo karne</option>
            <option value="Prawo korporacyjne/biznesowe">Prawo korporacyjne/biznesowe</option>
            <option value="Prawo pracy">Prawo pracy</option>
            <option value="Prawo rodzinne">Prawo rodzinne</option>
            <option value="Prawo nieruchomości">Prawo nieruchomości</option>
            <option value="Inna">Inna</option>
          </select>

          {formData.specialisation == "Inna" && <input placeholder={"Specjalizacja"} type="text" className="gaius-input" value={formData.specialistaionAnother} onChange={(e) => { setformData({ ...formData, specialistaionAnother: e.target.value }) }} />}

        </>
      }

      <Button
        variant="contained-dark"
        type="button"
        onClick={() => {
          fetch("https://hook.eu2.make.com/iefu88llk8yrw69flrx5vvw4dxca3icy", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
          }).then(() => {
            Cookies.set("quote", true, { expires: 3 }); // 3 days
            setformData({ ...formData, send: true })
          });
        }}
        disabled={formData.disabled}>

        {quote ? "Wysłano zapytanie" : "Wyślij zapytanie"}
      </Button>

    </div>
  );
}

const PaymentsOneOffRow: React.FC<PaymentBox2Props> = ({ type, oldPrice, price, period, comment, lowest, periodCode, link }) => {
  const paymentStore = React.useState(PaymentStore())[0];

  return (
      <div className={"payments__one-off"}>
        <div className="payments__one-off__data">
          <h3>{type}</h3>
          <div className="payments__one-off__price">{oldPrice != price && <span className="payments__one-off__old-price">{oldPrice}zł&nbsp;</span>}{price}zł
          </div>
          <div className="payments__version__comment">{lowest ? "najniższa cena w ciągiu 30 dni: " + lowest : "\u00A0"}</div>
        </div>
        <a onClick={() => {
          paymentStore.makeOneOffPayment("/", link).then((res: any) => {
            document.location.href = res.href;
          })
        }}>

          <Button
              variant="contained-dark"
              type="submit">
            Wybierz
          </Button>
        </a>
      </div>
  )
}

const PaymentsOneOff = () => {
  const [modalData, setmodalData] = useState({open: false, disabled: false});
  const paymentStore = React.useState(PaymentStore())[0];

  return (
      <>
        <p className={"payments__link"} onClick={() => setmodalData({...modalData, open: true})}>Lub dokonaj płatności
          jednorazowej</p>
        {modalData.open &&
            <div className="payments__modal-container">
              <div className="payments__modal">
                <main className="payments__modal__container">
                  <h2 className="payments__header-h2">Kup dostęp</h2>
                  <p className="payments__header-p">Dostęp do Gaius Lex dostaniesz od razu po zaksięgowaniu płatności. Po zakończeniu okresu subskrpycji, nie przedłuży się ona automatycznie.</p>
                  <div className="payments__one-off__container">
                    <PaymentsOneOffRow
                      type="Miesiąc"
                      oldPrice="359"
                      price="359"
                      period="netto m-c"
                      comment="*Cena obniżona ze względu na wczesny dostęp"
                      lowest="359 zł"
                      periodCode="monthly"
                      link="monthly"
                    />
                    <PaymentsOneOffRow
                        type="Kwartał"
                        oldPrice="1077"
                        price="899"
                        period="netto m-c"
                        comment="*Cena obniżona ze względu na wczesny dostęp"
                        lowest="899 zł"
                        periodCode="quarterly"
                        link="quarterly"
                    />
                    <PaymentsOneOffRow
                        type="Rok"
                        oldPrice="4308"
                        price="2889"
                        period="netto m-c"
                        comment="*Cena obniżona ze względu na wczesny dostęp"
                        lowest="2889 zł"
                        periodCode="yearly"
                        link="yearly"
                    />
                  </div>
                  <p className={"payments__link"} style={{textAlign: "center"}} onClick={() => setmodalData({...modalData, open: false})}>Wróć</p>
                </main>
              </div>
            </div>
        }
      </>
  )
}

const Payments = () => {
  const { userData } = useUserData();
  const refferal = userData?.referral;
  const [showPricing, setShowPricing] = useState(refferal == null || refferal == "sales" ? false : true);

  const name = userData?.firstName + " " + userData?.lastName;
  const email = userData?.email;
  const phone = userData?.phone;

  const tillTrialEnds = userData?.subscriptionStatus?.tillTrialEnds;
  const isSubscribed = userData?.subscriptionStatus?.enabled;
  const isCanceled = userData?.subscriptionStatus?.isCanceled;
  const endDate = userData?.subscriptionStatus?.endDate;
  const isTrial = userData?.subscriptionStatus?.isTrial;

  const handleShowPricing = () => {
    setShowPricing(!showPricing);
  }

  return (
    <div className="payments__container">
      <header className="payments__header payments__box">
        <h2 className="payments__header-h2">Wybierz pakiet Gaius Lex</h2>
        <p className="payments__header-p">Gaius pomoże Ci przygotować strategię, rozplanować strukturę pisma, napisać dokument, oraz wiele, wiele więcej!</p>
      </header>
      <SubscriptionStatus isSubscribed={isSubscribed} tillTrialEnds={tillTrialEnds} isTrial={isTrial} isCanceled={isCanceled} endDate={endDate} />
      <div className="payments__page payments__box" >
        {!showPricing ?
          <QuoteForm
            isSubscribed={isSubscribed}
            isTrial={isTrial}
            name={name}
            email={email}
            phone={phone} />
       :
       <>
        <PaymentsOneOption
         type="Wczesny dostęp"
          oldPrice="829"
          price="329"
          period="netto m-c"
          comment="*Cena obniżona ze względu na wczesny dostęp"
         lowest="329 zł"
         periodCode="monthly"
         link="monthly" />
        <PaymentsOneOff />
       </>
        }
        <Button
          variant="outlined-light"
          type="button"
          onClick={handleShowPricing}>
          {showPricing ? "Chcę umówić się na konsultację" : "Chcę kupić bez konsultacji"}
        </Button>

       
        {/* <div className="payments__legend">
          <div className="payments__legend__header">
            <h3>Zapłać z góry i oszczędzaj</h3>
          </div>
          <div className="payments__legend__list">
            <div className="payments__legend__list__item">
              <p>Dostęp do modułu orzeczeń</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Dostęp do modułu kodeksów</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Dostęp do modułu interpretacji podatkowych</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Dostęp do modułu „Porozmawiaj z gaiusem”</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Dostęp do modułu „Analizuj moje dokumenty”</p>
            </div>
            <div className="payments__legend__list__item" style={{ minHeight: 82 }}>
              <p>Dostęp do zamkniętego newslettera prawnego informującego o najważniejszych nowinach z prawa co piątek 15:00</p>
            </div>
            <div className="payments__legend__list__item" style={{ minHeight: 64 }}>
              <p>Newsletter o nowych aktualizacjach/danych i poczynań Gaius-Lexa na międzynarodowym rynku</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Wsparcie techniczne 9:00 – 21:00</p>
            </div>
            <div className="payments__legend__list__item">
              <p>Dedykowany opiekun klienta</p>
            </div>
          </div>
        </div> */}
        {/* <div className="payments__table" >
          <PaymentBox2
            type="Miesięczny"
            price="329"
            period="netto m-c"
            comment=""
            lowest="329 zł"
            periodCode="monthly"
            link="monthly" />
          <PaymentBox2
            type="Kwartalny"
            price="276"
            period="netto m-c"
            comment="Przy płatności z góry 828 zł za 3 m-c"
            lowest="828 zł"
            periodCode="quarterly"
            link="quarterly" />
          <PaymentBox2
            type="Roczny"
            price="219"
            period="netto m-c"
            comment="Przy płatności z góry 2628 zł za 12 m-c"
            lowest="2628 zł"
            periodCode="yearly"
            link="yearly" />
        </div> */}
      </div >
      <PaymentsHistory /><br/>
    </div>
  );
}

export default Payments;
export { SubscriptionStatus }