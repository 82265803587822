
import * as React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link

} from "react-router-dom";

import Container from "./components/container";
import BrowseDocuments from "./pages/browseDocuments";
// import SemanticSearch from "./pages/semanticSearch";
import Home from "./pages/home";
import AnalyzeDocument from "./pages/analyzeDocument";
import ShowDocument from './pages/showDocument';
import CreateDocument from './pages/createDocument';
import ActivateTrial from './pages/activateTrial';
import InteractiveQuery from './pages/interactiveQuery';
import InteractiveSearch from './pages/interactiveSearch';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Snackbar, Alert } from '@mui/material';
import { SnackbarContext, SnackbarProvider } from './contexts/SnackbarContext';

import LoginView from './pages/login';
import RegisterView from './pages/register';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import CaseLawSearch from './pages/caseLawSearch';
import CaseLawDocumentAnalysis from './pages/caseLawDocumentAnalysis';
import Payments from './pages/payments';
import ChatWithGPT from './pages/chatWithGPT/ChatWithGPT';
import HistoricalQueries from './pages/historicalQueries';

import ShowCachedResults from './pages/showCachedResults';
import { UserDataProvider } from './contexts/UserDataProvider';
import { PrivacyPolicyPage, PrivacyPolicyNewPage } from './pages/privacyPolicy';
import { Statute, StatuteNew } from "./pages/statute";
import Feedback from './pages/feedback';
import Team from './pages/aboutUs/Team';
import Mission from './pages/aboutUs/Mission';
import DataSources from './pages/aboutUs/DataSources';
import ContractAnalysis from './pages/contractAnalysis';
import CookieConsentModal from './components/CookieConsentModal';
import KnowledgeBase from './pages/knowledgeBase';
import PasswordResetRequest from './pages/passwordReset';
import PasswordReset from './pages/passwordReset/id/token';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Profile from "./pages/profile";
import StatueConsentModal from "./components/StatueConsentModal";

const theme = createTheme({
  palette: {
    primary: {
      main: '#19437D',
    },
    secondary: {
      main: '#ff3d00',
    },
    text: {
      secondary: "#8D9DA8"
    }
  },
  typography: {
    "fontFamily": "sofia-pro, sans-serif"
  }
})

const ICONS = {
  "success": <CheckCircleIcon color="success" />,
  "error": <CancelIcon color="error" />,
  "info": <InfoIcon color="info" />, 
  "warning": <WarningIcon color="warning" />
}

const InContextSnackBar = () => {
  const snackbar = React.useContext(SnackbarContext);
  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.duration}
      onClose={() => { snackbar.setOpen(false); }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={() => { snackbar.setOpen(false); }}
        severity={snackbar.severity}
        sx={{ width: '100%', borderRadius: "0.75rem" }}
        icon={ICONS[snackbar.severity]}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <InContextSnackBar />
        <BrowserRouter>
          <UserDataProvider>
            <CookieConsentModal />
            <Routes>
              <Route path="login" element={<LoginView />} />
              <Route path="register" element={<RegisterView />} />
              <Route path="password-reset" element={<PasswordResetRequest />} />
              <Route path="password-reset/:id/:token" element={<PasswordReset />} />
              <Route path="privacy-policy" element={<PrivacyPolicyNewPage />} />
              <Route path="privacy-policy-2024" element={<PrivacyPolicyPage />} />
              <Route path="statute" element={<Statute />} />
              <Route path="statute-new" element={<StatuteNew />} />
              <Route element={<PersistLogin />} >
                <Route path="payments" element={<Container />}>
                  <Route path="" element={<Payments />} />
                </Route>
                <Route element={<RequireAuth premiumRequired={false} />}>
                  <Route path="/" element={<Container />}>
                    <Route path="" element={<Home />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="team" element={<Team />} />
                    <Route path="mission" element={<Mission />} />
                    <Route path="data" element={<DataSources />} />
                    <Route path="knowledge-base/*" element={<KnowledgeBase />} />
                  </Route>
                </Route>
                <Route path="/" element={<Container />}>
                  <Route element={<RequireAuth premiumRequired={true} />}>
                    <Route path="browse" element={<BrowseDocuments />} />
                    {/* <Route path="search" element={<SemanticSearch />} /> */}
                    <Route path="analyze-document" element={<AnalyzeDocument />} />
                    <Route path="show-document" element={<ShowDocument />} />
                    <Route path="create-document" element={<CreateDocument />} />
                    <Route path="case-law-analysis" element={<CaseLawDocumentAnalysis />} />
                    <Route path="contract-analysis" element={<ContractAnalysis />} />
                    <Route path="case-law-search" element={<CaseLawSearch />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="/" element={<Home />} />
                    <Route path="chat" element={<ChatWithGPT />} />
                    <Route path="history" element={<HistoricalQueries />} />
                    <Route path="feedback" element={<Feedback />} />
                    <Route path="interactive-query" element={<InteractiveQuery />} />
                    <Route path="interactive-search" element={<InteractiveSearch />} />
                  </Route>
                  <Route path="show-results" element={<ShowCachedResults />} />
                </Route>
              </Route>
              <Route path="/" element={<LoginView />} />
            </Routes>
          </UserDataProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
