import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Pagination, Paper, Stack, TextField, Typography, debounce } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useGaiusWebSocket from '../hooks/useGaiusWebSocket';
import LinearDeterminate from '../components/LinearBuffer';
import DocumentParser from '../components/DocumentParser';
import getCSRFToken from "../stores/CSRFStore";

type DocumentQuestionAnswererProps = {
  path?: string;
  documentId?: string;
}

const DocumentQuestionAnswerer = ({ path, documentId }: DocumentQuestionAnswererProps) => {
  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [sources, setSources] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const theme = useTheme();
  const axios = useAxiosPrivate();
  const params = new URLSearchParams(window.location.search);

  const { sendMessage, lastMessage } = useGaiusWebSocket("/ws/v1/query-doc");

  React.useEffect(() => {
    let url = `api/v1/sample-questions`;
    if (documentId)
      url += `?id=${documentId}`;
    else if (path)
      url += `?path=${path}`;
    else
      url += `?source=${params.get('source')}&docId=${params.get('docId')}`;

    axios.get(url)
      .then(response => {
        setSampleQuestions(response.data.questions);
      });
  }, []);

  async function askQuestion(question: string) {
    const msg = {
      path: path?.replace("//", ""),
      id: documentId,
      text: question,
      isFile: true,
      source: params.get('source'),
      docId: params.get('docId'),
    };
    sendMessage(JSON.stringify(msg));
    setLoading(true);
    setAnswer("");
    setSources([]);
  }

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "searching") {
        setMessage("Wyszukiwanie...");
      }
      else if (data.message === "verifying") {
        setMessage("Analiza wyników...");
      }
      else if (data.message === "generating") {
        setLoading(false);
        setMessage("");
        setAnswer(data.answer);
        setSources(data.documents);
      }
    }

  }, [lastMessage]);

  return (
    <Box p={3}>
      <Stack direction="column" spacing={2}>
        <TextField
          id="question"
          label="Zadaj pytanie"
          variant="outlined"
          value={question}
          onChange={(event: { target: { value: any; }; }) => setQuestion(event.target.value)}
          onKeyDown={(event: { keyCode: number; }) => {
            if (event.keyCode === 13) {
              askQuestion(question);
            }
          }}
        />
      </Stack>
      <Box mt={2} mb={2} display="flex" justifyContent="end">
        <Button
          variant="contained"
          onClick={() => askQuestion(question)}
          disabled={loading}
        >
          Znajdź odpowiedź
        </Button>
      </Box>
      {
        sampleQuestions.map((sampleQuestion: {
          question: string;
          answer: string;
          source: string;
        }) => (
          <Box mt={1} mb={1}>
            <Button
              variant="outlined"
              onClick={() => {
                console.log(sampleQuestion);
                setQuestion(sampleQuestion.question);
                setAnswer(sampleQuestion.answer);
                setSources([sampleQuestion.source]);
              }}
            >
              {sampleQuestion.question}
            </Button>
          </Box>
        ))
      }
      {loading &&
        <>
          <Typography variant="body1" component="p">
            {message}
          </Typography>
          <LinearDeterminate maxTime={20} />
        </>
      }
      {!loading && answer && (
        <Box p={2} className="shadow-sm" sx={{ border: "2px solid " + theme.palette.primary.main, borderRadius: "8px" }}>
          <Typography variant="body1" component="p">
            {answer}
          </Typography>
        </Box>
      )}
      {!loading && answer && (
        <Box mt={2}>
          <Typography variant="h6" component="h6">
            Źródła:
          </Typography>
          {sources.map((source: any) => (
            <ExpandableBox text={source} />
          ))}
        </Box>
      )}
    </Box>
  );
}

const DocumentViewer = ({ path, documentId }: { path?: string, documentId?: string }) => {
  const [document, setDocument] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [appliedSearchTerm, setAppliedSearchTerm] = useState('');
  const [url, setUrl] = useState('');
  const [searchResults, setSearchResults] = useState<Array<[string, number]>>([]);
  const params = new URLSearchParams(window.location.search);

  const axios = useAxiosPrivate();

  useEffect(() => {
    if (documentId) {
      axios.get(`api/v1/judgement-url?id=${documentId}`)
        .then(response => {
          setUrl(response.data.url);
        })
        .catch(error => {
          console.log(error);
        });
    }

    let url;
    if (path)
      url = `api/v1/document?path=${path}&page=${page}`;
    else if (documentId)
      url = `api/v1/document?id=${documentId}&page=${page}`;
    else
      url = `api/v1/document${window.location.search}&page=${page}`;

    axios.get(url)
      .then(response => {
        setDocument(response.data.content);
        setTotalPages(response.data.totalPages);
        setUrl(response.data.url);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [path, documentId, page]);

  React.useEffect(() => {
    getCSRFToken();
  }, []);

  const downloadFile = () => {
    const win = window.open(`${process.env.BACKEND_ENDPOINT}/api/v1/download${window.location.search}`, '_blank');
    win?.focus();
  }

  const searchDocument = React.useCallback((value: string) => {
    if (value.length < 3)
      setSearchResults([]);

    let url;
    if (path)
      url = `api/v1/document-search?path=${path}&query=${value}`;
    else if (documentId)
      url = `api/v1/document-search?id=${documentId}&query=${value}`;
    else
      url = `api/v1/document-search${window.location.search}&query=${value}`;

    axios.get(url)
      .then(response => {
        setSearchResults(response.data);
      })
      .catch(error => {
        console.log(error);
      });

  }, []);

  const debouncedSearchDocument = React.useMemo(() => debounce(searchDocument, 500), [searchDocument]);
  const debouncedSetSearchTerm = React.useMemo(() => debounce(setAppliedSearchTerm, 500), [setAppliedSearchTerm]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={8}>
        <Box p={3}>
          {/* Search box */}
          <TextField
            id="search"
            label="Szukaj"
            variant="outlined"
            value={searchTerm}
            className='mb-3'
            onChange={(event: { target: { value: any; }; }) => {
              setSearchTerm(event.target.value);
              debouncedSetSearchTerm(event.target.value);
              debouncedSearchDocument(event.target.value);
            }}
            onKeyDown={(event: { keyCode: number; }) => {
              if (event.keyCode === 13) {
                searchDocument(searchTerm);
              }
            }}
            fullWidth
          />
          {
            searchResults.length > 0 ? (
              searchResults.map((result: [string, number]) => (
                <SearchResult text={result[0]} onClick={async () => {
                  setPage(result[1]+1)
                  setSearchResults([]);
                }}
                  query={appliedSearchTerm}
                  key={result[1].toString()}
                />
              ))
            )
              : null
          }
          {
            searchResults.length === 0 ? (
              <Paper elevation={0} className="border">
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box p={2}>
                    <Typography variant="body1" component="pre" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", textAlign: "justify" }}>
                      <DocumentParser document={document} parse={true} searchTerm={appliedSearchTerm} />
                    </Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="center">
                  <Pagination count={totalPages} page={page} onChange={(_event: any, value: any) => setPage(value)} />
                </Box>
              </Paper>
            ) : null
          }
        </Box>
      </Grid>
      <Grid item sm={12} md={4}>
        {url && (
          <Button
            variant="text"
            href={url}
            target="_blank"
            rel="noreferrer"
            fullWidth
            className="p-3 mt-4"
          >
            Przejdź do oryginalnego dokumentu
          </Button>
        )}
        <Button
          variant="text"
          fullWidth
          className="p-3 mt-4"
          onClick={downloadFile}
        >
          Pobierz jako DOCX
        </Button>
        <DocumentQuestionAnswerer path={path} documentId={documentId} />
        <CitingDocuments documentId={params.get('docId') || ''} source={params.get('source') || ''} />
      </Grid>
    </Grid>
  );
}

const ShowDocument = () => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path') || '';
  const documentId = searchParams.get('id') || '';

  return (
    <Box>
      <DocumentViewer path={path} documentId={documentId} />
    </Box>
  );
}

export default ShowDocument;

function ExpandableBox({ text }: any) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
      p={2}
      className="shadow-sm"
      sx={{
        borderRadius: "8px",
        maxHeight: expanded ? "none" : "200px",
        overflow: "hidden",
        cursor: "pointer",
        textFillColor: expanded ? "none" : "transparent",
        backgroundImage: expanded ? "none" : "linear-gradient(black, white)",
        backgroundClip: "text",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Typography variant="body1" component="p">
        {text}
      </Typography>
    </Box>
  );
}


const SearchResult = ({ query, text, onClick }: { query: string, text: string, onClick: () => {} }) => {
  return (
    <Paper elevation={0} className="border hover-shadow mb-2 p-3" onClick={onClick}>
      <Typography variant="body1" component="pre" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", textAlign: "justify" }}>
        <DocumentParser document={text} parse={true} searchTerm={query} />
      </Typography>
    </Paper>
  );
}

const CitingDocuments = ({ documentId, source }: { documentId: string, source: string }) => {
  const [citingDocuments, setCitingDocuments] = useState([]);
  const axios = useAxiosPrivate();

  useEffect(() => {
    axios.get(`api/v1/citing-documents?docId=${documentId}&source=${source}`)
      .then(response => {
        setCitingDocuments(response.data.citations);
      });
  }, []);

  console.log(citingDocuments)

  if (citingDocuments.length === 0) {
    return (
      <Box>
        <Typography variant="h6" component="h6">
          Cytujące dokumenty
        </Typography>
        <Box p={2}>
          <Typography variant="body1" component="p">
            Brak cytujących dokumentów
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" component="h6">
        Cytujące dokumenty
      </Typography>
      {citingDocuments.map((document: any) => {
        // highlight documentId
        const text = document.text.replace(documentId, `<strong>${documentId}</strong>`);
        const url = "?docId=" + document.doc_id + "&source=" + document.source;
        return (
          <CitationExpandableBox text={text} title={document.doc_id} url={url} />
        )
      })}
    </Box>
  );
}

const CitationExpandableBox = ({ text, title, url }: any) => {
  const [expanded, setExpanded] = React.useState(false);

  console.log(text);

  return (
    <Paper
      className="p-2 mb-2 shadow-sm"
      sx={{
        borderRadius: "8px",
        maxHeight: expanded ? "none" : "100px",
        overflow: "hidden",
        cursor: "pointer",
        textFillColor: expanded ? "none" : "transparent",
        backgroundImage: expanded ? "none" : "linear-gradient(black, white)",
        backgroundClip: "text",
        backgroundColor: expanded ? "white" : "transparent",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Typography variant="h6" component="h6">
        {title}
      </Typography>
      <Typography variant="body1" component="p" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: text }} />
      <Button
        variant="text"
        href={url}
        target="_blank"
        rel="noreferrer"
        fullWidth
        className="p-3 mt-4"
      >
        Przejdź
      </Button>
    </Paper>
  );
}