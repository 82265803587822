import React, {
    SetStateAction,
    createContext,
    useState,
    Dispatch,
} from "react";


type SubscriptionStatus = {
    enabled: boolean;
    endDate: string;
    isCanceled: boolean;
    isTrial: boolean;
    tillTrialEnds: number;
};

type UserData = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    user_plan: {
        name: string;
        trial: boolean;
        till_trial_ends: number | null;
    };
    isLoggedIn: boolean;
    isVerified: boolean;
    privacyConsent: string | null;
    statueConsent: string | null;
    isSocialLogin: boolean;
    hasSecretAnswers: boolean;
    encryptionDisabled: boolean;
    dataUsageNotice: string | null;
    subscriptionStatus: SubscriptionStatus;
    referral: string | null;
};

interface UserDataContextInterface {
    userData: UserData;
    setUserData: Dispatch<SetStateAction<UserData>>;
}

const UserDataContext = createContext<UserDataContextInterface>({
    userData: {
        firstName: "First Name",
        lastName: "Last Name",
        phone: "phone",
        email: "email",
        user_plan: {
            name: "subscription",
            trial: false,
            till_trial_ends: null,
        },
        isLoggedIn: false,
        isVerified: false,
        privacyConsent: null,
        statueConsent: null,
        isSocialLogin: false,
        hasSecretAnswers: false,
        encryptionDisabled: false,
        dataUsageNotice: null,
        subscriptionStatus: {
            enabled: false,
            endDate: "",
            isCanceled: false,
            isTrial: false,
            tillTrialEnds: 7,
        },
        referral: null
    },
    setUserData: () => { },
});


export const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
    // const { userData, setUserData } = React.useContext(UserDataContext);
    const [userData, setUserData] = useState<UserData>({
        firstName: "First Name",
        lastName: "Last Name",
        phone: "phone",
        email: "email",
        user_plan: {
            name: "subscription",
            trial: false,
            till_trial_ends: null,
        },
        isLoggedIn: false,
        isVerified: false,
        privacyConsent: null,
        statueConsent: null,
        isSocialLogin: false,
        hasSecretAnswers: false,
        encryptionDisabled: false,
        dataUsageNotice: null,
        referral: null,
        subscriptionStatus: {
            enabled: false,
            endDate: "",
            isCanceled: false,
            isTrial: false,
            tillTrialEnds: 7,
        },
    });

    return (
        <UserDataContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserDataContext.Provider>
    );
};

export default UserDataContext;
