import * as React from 'react';
import { Box, TextField, Typography, List, ListItem, Divider, LinearProgress } from '@mui/material';
import Button from '../../components/generic/Button';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { Chip } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SearchIcon from '@mui/icons-material/Search';

interface DocumentsTilesProps {
  path: string;
}

const DocumentsTiles = ({ path }: DocumentsTilesProps) => {
  const [documents, setDocuments] = React.useState([]);
  const axios = useAxiosPrivate();

  React.useEffect(() => {
    if (path.length > 2) {
      axios.get(`api/v1/list-documents?path=${path}`)
        .then(response => {
          setDocuments(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [path]);

  return (
    <Box className="border border-solid p-3" sx={{ borderRadius: "8px" }}>
      <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>Przeszukiwane pliki</Typography>
      <List>
        {
          documents &&
          documents.map((document: any, index: number) => {
            const name = document.replace(path, '').replace('///', '');

            return (
              <Chip
                key={index}
                icon={<DescriptionIcon />}
                label={name}
                variant="outlined"
                sx={{ m: 0.5 }}
              />
            );
          })
        }
      </List>
    </Box>
  );
}

const SemanticSearch = () => {
  const [collection, setCollection] = React.useState('test-collection');
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<{ output_text: string, input_documents: any[] } | null>(null);
  const [loading, setLoading] = React.useState(false);

  const snackbar = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();

  function search() {
    setLoading(true);
    setResults(null);

    axios.post(`api/v1/query-documents`,
      JSON.stringify({
        path: collection,
        text: query
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setResults(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        snackbar.setMessage('Wystąpił błąd podczas wyszukiwania dokumentów');
        snackbar.setSeverity('error');
        snackbar.setOpen(true);
        setLoading(false);
      });
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div id={"semantic-search-bar"}>
        <h1>Przeszukaj dokumenty</h1>
        {/* <TextField
        label="Baza wiedzy"
        variant="outlined"
        size="small"
        value={collection}
        onChange={(event) => {
          setCollection(event.target.value);
        }}
      /> */}
        <TextField
          label="Zadaj pytanie"
          variant="outlined"
          size="small"
          value={query}
          sx={{ width: "100%" }}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          onKeyDown={(event: { keyCode: number }) => {
            if (event.keyCode === 13) {
              search();
            }
          }}
          InputProps={{
            endAdornment: (
              <Button id={"search-icon"} onClick={search} disabled={loading}>
                <SearchIcon />
              </Button>
            ),
          }}
        />
        {/* <DocumentsTiles path={collection} /> */}
        <Divider />
        {loading && (
          <div style={{ textAlign: "center", color: "#8D9DA8" }}>
            <Typography variant="h6" className="mb-2">
              Trwa analiza...
            </Typography>
            <LinearProgress />
          </div>
        )}
      </div>
      {results && (
        <>
          <div id={"semantic-search-results"}>
            <h1>Wyniki</h1>
            <Typography variant="body1">{results.output_text}</Typography>
          </div>
          <div id={"semantic-search-sources"}>
            <h1>Źródła</h1>
            <List>
              {results.input_documents.map((source: any, index: any) => {
                return (
                  <ListItem key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {/* italic */}
                      <Typography
                        variant="body1"
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        (...){source[0][1]}(...)
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "end" }}>
                        {source[1][1].source}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </>
      )}
    </Box>
  );
};

export default SemanticSearch;